import { Location } from 'vue-router';

export default class TableHead {
  label: string | null = null
  filterKey = ''
  hasLabel = true
  type = 'string'
  linkName: string | null = ''
  linkParam = ''
  translationPrefix = ''

  constructor (
    label: string | null,
    filterKey: string | null,
    hasLabel = true,
    type = 'string',
    linkName: string | null = null,
    linkParam = '',
  ) {
    this.label = label;
    this.filterKey = filterKey || '';
    this.hasLabel = hasLabel;
    this.type = type;
    if (type === 'translation' && linkName) {
      this.translationPrefix = linkName;
    } else {
      this.linkName = linkName;
    }
    this.linkParam = linkParam;
  }

  linkObject (params: string): Location | null {
    if (this.linkName) {
      const linkParams: any = {};
      linkParams[this.linkParam] = params;
      return {
        name: this.linkName,
        params: linkParams,
      };
    }

    return null;
  }

  showLabel (): boolean {
    return this.label !== null && this.hasLabel;
  }

  translationKey (data: any): string {
    return `${this.translationPrefix}${data[this.filterKey]}`;
  }
}
