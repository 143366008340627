<template lang="pug">
  .jwl-sortable-table(v-if="tableData.length > 0")
    table.jwl-sortable-table__table(:class="themeClass('jwl-sortable-table__table')")
      thead
        tr
          th(v-for="(th, index) in tableHead")
            .jwl-sortable-table__th(v-if="th.showLabel()")
              | {{$t(th.label)}}

              .jwl-sortable-table__sort-container
                button.jwl-sortable-table__sort-button(@click='sortTable(index, false)' :class="tableSortClass(index, false)")
                  icon-long-arrow-down
                button.jwl-sortable-table__sort-button(@click="sortTable(index, true)" :class="tableSortClass(index, true)")
                  icon-long-arrow-up

      tbody
        tr(v-for="data in sortedData")
          td(v-for="th in tableHead")
            template(v-if="th.type === 'string'")
              slot(:name="th.filterKey" :rowData="data")
                | {{data[th.filterKey]}}
            template(v-else-if="th.type === 'translation'")
              | {{$t(th.translationKey(data))}}
            a.jwl-sortable-table__table-link.jwl-sortable-table__table-link--email(v-else-if="th.type === 'email'" :href="`mailto:${th.translationKey(data)}`")
              | {{th.translationKey(data)}}
            template(v-else-if="th.type === 'date'")
              | {{formattedDate(data[th.filterKey])}}
            template(v-else-if="th.type === 'boolean'")
              | {{data[th.filterKey] ? $t('general.yes') : $t('general.no')}}
            template(v-else-if="th.type === 'list'")
              | {{data[th.filterKey].length}}
              ul.jwl-sortable-table__hover-overlay(v-if="data[th.filterKey].length > 0")
                li(v-for="listItem in data[th.filterKey]")
                  | {{listItem}}
            router-link.jwl-sortable-table__table-link(v-else-if="th.type === 'link'" :to="th.linkObject(data.id)")
              | {{$t(`general.${th.linkName}`)}}
            template(v-else)
              | Unknown Table type

    .jwl-sortable-table__pagination-container(v-if="lastPage > 1")
      button.jwl-sortable-table__pagination-arrow(:class="pageBeforeClass" @click="page = pageBefore")
        icon-long-arrow-left
      template(v-for="number in pages")
        .jwl-sortable-table__pagination-step(v-if="isNaN(number)")
          input.jwl-sortable-table__pagination-form(placeholder="go to" v-model="toPage")
        .jwl-sortable-table__pagination-step(v-else @click="page = number" :class="activeNumberClass(number)")
          | {{number}}
      button.jwl-sortable-table__pagination-arrow(:class="pageAfterClass" @click="page = pageAfter")
        icon-long-arrow-right

</template>

<script>
const IconLongArrowDown = () => import('common-modules/src/assets/fa-icons/duotone/long-arrow-down.svg');
const IconLongArrowUp = () => import('common-modules/src/assets/fa-icons/duotone/long-arrow-up.svg');
const IconLongArrowLeft = () => import('common-modules/src/assets/fa-icons/duotone/long-arrow-left.svg');
const IconLongArrowRight = () => import('common-modules/src/assets/fa-icons/duotone/long-arrow-right.svg');

export default {
  name: 'sisSortableTable',
  props: {
    tableHead: Array,
    tableData: Array,
    tableTheme: {
      type: String,
      default: 'primary',
      required: false,
    },
  },
  components: {
    IconLongArrowDown,
    IconLongArrowUp,
    IconLongArrowLeft,
    IconLongArrowRight,
  },
  data () {
    return {
      sortKey: 0,
      reverse: false,
      rowsToDisplay: 50,
      page: 1,
      toPage: null,
      toPageTimer: null,
    };
  },
  methods: {
    sortTable (key, order) {
      this.page = 1;
      this.sortKey = key;
      this.reverse = order;
    },
    tableSortClass (index, order) {
      return {
        'jwl-sortable-table__sort-button--active': this.sortKey === index && this.reverse === order,
      };
    },
    activeNumberClass (number) {
      return {
        'jwl-sortable-table__pagination-step--active': this.page === number,
      };
    },
    goToPage (number) {
      const toPage = Number(number);
      if (toPage <= 0) {
        this.page = 1;
      } else if (toPage > this.lastPage) {
        this.page = this.lastPage;
      } else {
        this.page = toPage;
      }
    },
    themeClass (selector) {
      return `${selector}--${this.tableTheme}`;
    },
    formattedDate (ISODateString) {
      const date = new Date(ISODateString);
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
      return `${monthName} ${date.getUTCFullYear()}`;
    },
  },
  computed: {
    sortedData () {
      const array = this.tableData;

      const sortedArray = array.sort((a, b) => {
        let sortKey = this.tableHead[this.sortKey].filterKey;
        let aSearchTerm = a[sortKey] ? a[sortKey].toString().toLowerCase() : '';
        let bSearchTerm = b[sortKey] ? b[sortKey].toString().toLowerCase() : '';

        if (!aSearchTerm && !bSearchTerm) {
          const altIndex = this.sortKey > 0 ? this.sortKey - 1 : this.sortKey + 1;
          sortKey = this.tableHead[altIndex].filterKey;
          aSearchTerm = a[sortKey] ? a[sortKey].toString().toLowerCase() : '';
          bSearchTerm = b[sortKey] ? b[sortKey].toString().toLowerCase() : '';
        }

        return this.reverse
          ? bSearchTerm.localeCompare(aSearchTerm)
          : aSearchTerm.localeCompare(bSearchTerm);
      });

      return sortedArray.slice((this.page - 1) * this.rowsToDisplay, this.rowsToDisplay * this.page);
    },
    lastPage () {
      return Math.ceil(this.tableData.length / this.rowsToDisplay);
    },
    pages () {
      const countPages = this.lastPage;
      const returnArray = [];
      const current = this.page;
      let earliestPage;
      let latestPage;
      returnArray.push(1);
      if (current <= 7 || countPages <= 10) {
        earliestPage = 2;
      } else {
        returnArray.push('...');
        earliestPage = current - 3;
      }
      if (current <= 7 && countPages > 10) {
        latestPage = 7;
      } else if (countPages - current <= 7 || countPages <= 10) {
        latestPage = countPages - 1;
      } else {
        latestPage = current + 3;
      }
      for (let i = earliestPage; i <= latestPage; i += 1) {
        returnArray.push(i);
      }
      if (latestPage !== countPages - 1) {
        returnArray.push('...');
      }
      returnArray.push(this.lastPage);
      return returnArray;
    },
    pageBeforeClass () {
      const attributeClasses = [`jwl-sortable-table__pagination-arrow--${this.tableTheme}`];
      if (this.page <= 1) {
        attributeClasses.push('jwl-sortable-table__pagination-arrow--disabled');
      }
      return attributeClasses.join(' ');
    },
    pageBefore () {
      return Math.max(this.page - 1, 1);
    },
    pageAfterClass () {
      const attributeClasses = [`jwl-sortable-table__pagination-arrow--${this.tableTheme}`];
      if (this.page >= this.lastPage) {
        attributeClasses.push('jwl-sortable-table__pagination-arrow--disabled');
      }
      return attributeClasses.join(' ');
    },
    pageAfter () {
      return Math.min(this.page + 1, Number(this.lastPage));
    },
  },
  watch: {
    toPage (newPage, oldPage) {
      if (oldPage !== newPage && newPage != null) {
        if (this.toPageTimer !== null) {
          window.clearTimeout(this.toPageTimer);
        }
        this.toPageTimer = window.setTimeout(() => {
          this.toPageTimer = null;
          this.goToPage(newPage);
          this.toPage = null;
        }, 500);
      }
    },
  },
};
</script>

<style lang="sass">
  @import "../styles/jwlSortableTable"
</style>
